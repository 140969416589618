import {daysOfWeek} from "@/struct/misc";

export const $me = {
    username: '',
    first_name: '',
    last_name: '',
    password: '',
    email: '',
    role: localStorage.getItem('role_id'),
    role_name: '',
    last_login: '',
    two_factor_auth: false,
    telegram: {
        telegram_chat_id: '',
        notify_type: '',
        notify_type_quantity: 0,
        notify_type_period_offset: 0,
        notify_type_period_last_notification: '1970-01-01 00:00:00',
        notify_type_quantity_countries_last_value: '',
        notify_type_quantity_integrations_last_value: '',
        telegram_last_message_query: '',
    }
}

export const controlGroupWorkingHoursItem = (day) => ({
    label: day,
    flag: false,
    hours: {
        0: false, 1: false, 2: false, 3: false, 4: false, 5: false,
        6: false, 7: false, 8: false, 9: false, 10: false, 11: false,
        12: false, 13: false, 14: false, 15: false, 16: false, 17: false,
        18: false, 19: false, 20: false, 21: false, 22: false, 23: false,
    },
    cap: {
        type: 'OFF',
        days: {
            Sunday: 0,
            Monday: 0,
            Tuesday: 0,
            Wednesday: 0,
            Thursday: 0,
            Friday: 0,
            Saturday: 0,
        }
    }
});

export const controlGroupWorkingHoursAll = daysOfWeek.map(day => controlGroupWorkingHoursItem(day));

export const $controlGroupTemplate = {
    name: '',
    notes: '',
    comments: '',
    working_hours_list: controlGroupWorkingHoursAll,
    geos: [],
    mapped: []
}