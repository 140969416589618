export function convertToParam(str, prefix) {
    // Convert camelCase and PascalCase to snake_case
    let result = str.replace(/([a-z])([A-Z])/g, "$1_$2"); // camelCase
    result = result.replace(/([A-Z])([A-Z][a-z])/g, "$1_$2"); // PascalCase

    // Convert to lowercase
    result = result.toLowerCase();

    // Replace special characters
    result = result.replace(/[_\-+]/g, "_"); // Replace -, _, + with _

    // Normalize to a consistent format
    result = result.replace(/__+/g, "_"); // Replace multiple _ with a single _
    result = result.replace(/^_|_$/g, ""); // Remove _ from start and end

    return `${prefix}${result}`;
}