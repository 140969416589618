<template lang="pug">
	#params-mapping-root
		#params-mapping-body
			el-row.text-letf
				el-form()
					el-row.text-left
						h4 Params mapping
						p Here you can find find, define or remove the mappijng of the properties that are inside the body of a params object. In SearchBoxApp there are 3 ways how to set the params object with key-pair values:&nbsp;
						ul
							li <b>iFrame:</b> The query params that come with the iframe - through the address bar are converted to a stringified JSON object and stored in the database
							li <b>Google Sheets:</b> All the headers of the columns that are defined in the worksheet (spreadsheet) setup form are treated as params
							li <b>Affiliate Network - API:</b> Some of the properties that are supported by the API are treated as params - For instance: "source", "product_sku", "CUSTOM1" etc.
						p You can define that specific params will be mapped to specific names - this will add additional columns to the lead's model and will force to save the value of the original props to the columns. It comes handy during Excel exports
							el-row.mt-2(:gutter="24")
						el-col(:span="24")
							el-form-item(label="Templates")
								el-button(type="info" icon="el-icon-plus" @click="toggleModal(0)") Add

					el-row.mt-2(:gutter="12")
						el-col(:xs="12" :sm="6")
							el-form-item(label="Search...")
								el-input(placeholder="Search By Keyword..."  v-model="paginate.keyword", v-debounce:300ms="fetchData")

						el-col(:xs="12" :sm="6")
							el-form-item(label="In Use")
								el-select.w-100(v-model="filters.in_use" @change="fetchData")
									el-option(label="All" value="all" )
									el-option(label="Yes" value="1")
									el-option(label="No" value="0" )
						el-col(:xs="12" :sm="6")
							el-form-item.w-100(label=" By Mapped Value")
								el-select.w-100(v-model="filters.mapped" @change="fetchData")
									el-option(label="All" value="")
									el-option(v-for="o of options.params_mapping" :value="o.v" :label="o.t")

					el-table(id="params-mapping-tbl" v-loading="busy" stripe="" :data="list" element-loading-text='Loading', header-row-class-name="dark-header", border='', fit='', highlight-current-row='')
						el-table-column(align="center" label="#" width="75px")
							template(slot-scope="scope")
								span() {{ scope.row.id }}

						el-table-column(align="center" label="Original Key")
							template(slot-scope="scope" )
								span {{scope.row.key}}
						el-table-column(align="center" label="Mapped Key")
							template(slot-scope="scope" )
								span {{scope.row.mapped}}
						el-table-column(align="center" label="In Use" width="120px")
							template(slot-scope="scope" )
								el-tooltip(:content="scope.row.in_use ? 'In use by the system' : 'Not In Use'")
									font-awesome-icon.icon.alt.text-danger(v-if="!scope.row.in_use", :icon="['fas', 'times']")
									font-awesome-icon.icon.alt.text-success(v-else="", :icon="['fas', 'check']")
						el-table-column(align='center' label="Comments" )
							template(slot-scope="scope" )
								el-popover(v-if="scope.row.comments" placement="bottom" title="Time Data" trigger="click" :width="350" )
									label.d-block
										b Entry Comment for reference:
									el-input(type="textarea" readonly="" :rows="3" v-model="scope.row.comments" show-word-limit="")
									span.chooseable(slot="reference") Click To Show
								span.text-info(v-else="")
									i Missing
						el-table-column(align='center' label="Actions" width="150px")
							template(slot-scope="scope")
								el-tooltip(:content="`Toggle ${!scope.row.in_use ? 'on' : 'off' } this entry`")
									el-button.small-btn-action(:type="!scope.row.in_use ? 'success' : 'warning'" icon="el-icon-refresh" size="small" @click="toggle(scope.row.key, scope.row.in_use)")
								el-tooltip(content="Edit")
									el-button.small-btn-action(type="info", icon="el-icon-edit", size="small", @click="toggleModal(1, scope.row)")
					.d-flex.justify-content-between
						b-pagination.mt-3(v-model="paginate.page", :total-rows="count", :per-page="paginate.limit", aria-controls="data-list", @change="fetchData")

						el-row.mt-4
							el-col(:span="24")
								label.label-text-gray
									b Total:&nbsp;
									| {{ count | numeral('0,0') }}

						el-row
							el-col(:span="24")
								el-select.mt-3(filterable="", v-model="paginate.limit", @change="fetchData")
									el-option(label="10 Per Page", :value="10")
									el-option(label="15 Per Page", :value="15")
									el-option(label="20 Per Page", :value="20")
									el-option(label="50 Per Page", :value="50")
									el-option(label="100 Per Page", :value="100")
									el-option(label="200 Per Page", :value="200")
		#params-mapping-modal
			el-dialog.override-width.search-dialog(:title="`${!mode?'Create new':'Edit existing'} entry${mode?` - #${currentEntry && currentEntry.id}`:''}`" :visible.sync='showParamsMappingModal', width='75%', center='' @closed="cancelForm")
				el-row(:gutter="24")
					el-col(:span="24")
						el-row(:gutter="24")
							h5 Params Mapping Form

							p.letter-long() Fill the following form to add a new params mapping entry. You may leave a comment to this mapping as well as turn it on or off. The entries that are set to "off" won't override the original key during the registration and will remain in the database for reference only. Keep in mind
							p.text-success
								i <b>Note: </b> the original key must be unique, thus, the system won't allow to save a key that already exists in the database

				el-form(:form="form" :rules="rules" ref="params-mapping" :model="form")
					el-row(:gutter="24")
						el-col(:xs="24" :md="12" :lg="6")
							el-form-item(label="Original Key" prop="key")
								el-input(:maxlength="96" v-model="form.key" @change="generateMappedValue")
						el-col(:xs="24" :md="12" :lg="6")
							el-form-item(label="Mapped Key" prop="mapped")
								el-input(:maxlength="96" v-model="form.mapped")
						el-col(:xs="24" :md="6")
							el-form-item(label="Is used")
								el-switch.w-100(v-model="form.in_use" active-text="On" inactive-text="Off")
						el-col(:span="24")
							el-form-item(label="Comments" prop="comments")
								el-input(type="textarea" v-model="form.comments" :rows="3" maxlength="2048" show-word-limit="")
				span.dialog-footer(slot='footer')
					el-button(type='primary', @click='submit') Submit
					el-button(type='danger', @click='cancelForm') Cancel

</template>


<style lang="scss">

</style>


<script>
import {
	notEmpty,
	validateQueryParamsKey,
	validateQueryParamsKeyAndExists
} from "@/utils/validate";
import {convertToParam} from "@/utils";

function clearEmpty(data) {
	let obj = {};
	Object.entries(data).forEach(e => {
		if (e[1] !== '' && e[1] !== null && e[1] !== undefined)
			obj[e[0]] = e[1];
	});
	return obj;
}

const $form = {
	key: '',
	mapped: '',
	in_use: false,
	comments: ''
};

export default {
	name: 'params-mapping',
	data() {
		return {
			showParamsMappingModal: false,
			currentEntry: null,
			busy: false,
			mode: 0, // 0 - create, 1 - update,
			form: JSON.parse(JSON.stringify($form)),
			list: [],
			count: 0,
			paginate: {
				page: 1,
				limit: 10,
				keyword: ''
			},
			filters: {
				in_use: 'all',
				mapped: ''
			},
			rules: {
				key: { required: true, trigger: 'blur', validator: validateQueryParamsKeyAndExists, topic: 'params-mapping', app: this,},
				mapped: { required: true, trigger: 'blur', validator: validateQueryParamsKey,},
				comments: { required: false, trigger: 'blur'}
			},
			options: {
				params_mapping: []
			}
		}
	},
	mounted() {
		this.fetchData();
		this.fetchOptions();
	},
	methods: {
		generateMappedValue() {
			this.form.mapped = convertToParam(this.form.key, 'params_')
		},
		fetchOptions() {
			this.$apix.sendHttpRequest('GET', 'components/options', { filters: '4e9ca861-5047-474d-bada-836a4b212e3e' })
					.then(options => {
						this.options.params_mapping= options.params_mapping;
					})
		},
		fetchData() {
			setTimeout(() => {
				const body = {...this.paginate, ...clearEmpty(this.filters)};
				this.busy = true;
				this.$apix.sendHttpRequest('GET', 'params-mapping/list', body)
						.then(res => {
							this.busy = false
							this.list = res.rows;
							this.count = res.count;
						})
						.catch(err => {
							console.error(err);
							this.busy = false;
						})
			}, 200);
		},
		cancelForm() {
			this.showParamsMappingModal = false;
			this.currentEntry = null;
		},
		populateForm(entry) {
			this.form.key = entry.key;
			this.form.mapped = entry.mapped;
			this.form.in_use = entry.in_use;
			this.form.comments = entry.comments;
		},
		toggleModal(mode, entry) {
			this.mode = mode;
			this.showParamsMappingModal = true;
			this.form = JSON.parse(JSON.stringify($form));
			if (mode) {
				this.currentEntry = entry;
				console.log(entry)
				this.populateForm(entry);
				this.rules.key.original = entry.key
				this.rules.key.action = 'edit'
			} else {
				this.form = JSON.parse(JSON.stringify($form));
				this.rules.key.action = 'create'
				this.rules.key.original = undefined;
			}
		},
		toggle(key, currentFlag) {
			this.$apix.sendHttpRequest('PUT','params-mapping/toggle/' + key)
					.then(() => {
						this.$notify.success({
							title: 'API Response',
							message: 'The entry was toggled ' + (!currentFlag ? 'On' : 'Off') + 'Successfully'
						})
						this.fetchData();
					})
		},
		submit() {
			this.$refs['params-mapping'].validate(async valid => {
				if(valid) {
					let body = JSON.parse(JSON.stringify({...this.form}));
					delete body.type;
					if(this.mode) {
						body = JSON.parse(JSON.stringify({...this.form, original_key: this.currentEntry.key}));
						delete body.type;
						body.id = this.currentEntry.id;
					}
					this.busy = true;
					this.$apix.sendHttpRequest(this.mode ? 'PUT':'POST', 'params-mapping/'+(this.mode ? 'update' : 'create'), body)
							.then(() => {
								this.busy = false;
								this.showParamsMappingModal = false;
								this.fetchData();
								this.fetchOptions();
							})
							.catch(err => {
								console.error(err);
								this.busy = false;
							})
				}
			})
		}
	}
}
</script>